import React, { useState, useEffect } from "react";
import "./App.css";
import MarkerClusterGroup from "react-leaflet-cluster";
import {
  MapContainer,
  TileLayer,
  Marker as OriginalMarker,
  Popup,
  useMap,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

function App() {
  const [data, setData] = useState([]);
  const [center, setCenter] = useState([50.505, 12.09]);

  function ChangeView({ center, zoom }) {
    const map = useMap();
    map.setView(center, zoom);
  }

  function getCenterOfMarkers(markers) {
    if (markers.length === 1) {
      return markers[0];
    }

    let x = 0.0;
    let y = 0.0;
    let z = 0.0;

    markers.forEach((marker) => {
      let latitude = (marker[0] * Math.PI) / 180;
      let longitude = (marker[1] * Math.PI) / 180;

      x += Math.cos(latitude) * Math.cos(longitude);
      y += Math.cos(latitude) * Math.sin(longitude);
      z += Math.sin(latitude);
    });

    let total = markers.length;

    x = x / total;
    y = y / total;
    z = z / total;

    let centralLongitude = Math.atan2(y, x);
    let centralSquareRoot = Math.sqrt(x * x + y * y);
    let centralLatitude = Math.atan2(z, centralSquareRoot);

    console.log("centralLatitude", (centralLatitude * 180) / Math.PI);
    console.log("centralLatitude", (centralLongitude * 180) / Math.PI);

    return [
      (centralLatitude * 180) / Math.PI,
      (centralLongitude * 180) / Math.PI,
    ];
  }

  useEffect(() => {
    fetch(process.env.PUBLIC_URL + "/map_data.json")
      .then((response) => response.json())
      .then((data) => {
        setData(data);
        const markers = data.map((item) => [
          parseFloat(item["Zem sirka"]),
          parseFloat(item["Zem delka"]),
        ]);
        console.log("Found markers:" + markers.length);
        setCenter(getCenterOfMarkers(markers));
      });
  }, []);

  function Marker({ position, children, ...props }) {
    const map = useMap();

    /* const eventHandlers = {
      click: () => {
        map.flyTo(position, 13);
      },
    }; */

    return (
      <OriginalMarker
        position={position}
        /*         eventHandlers={eventHandlers}
         */ {...props}
      >
        {children}
      </OriginalMarker>
    );
  }

  return (
    <div className="App">
      <MapContainer center={center} zoom={10} scrollWheelZoom={true}>
        <ChangeView center={center} zoom={10} />

        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />

        <MarkerClusterGroup chunkedLoading>
          {data.map((item, index) => (
            <Marker
              key={index}
              position={[
                parseFloat(item["Zem sirka"]),
                parseFloat(item["Zem delka"]),
              ]}
              icon={L.icon({
                iconUrl: `${window.location.origin}/map-pin.svg`,
                iconSize: [25, 25],
              })}
            >
              <Popup>
                <p
                  style={{
                    marginTop: "0",
                    marginBottom: "0.5rem",
                    fontSize: "0.55rem",
                  }}
                >
                  ID: {index}
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  Okres: {item["Okres"]}
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  TP: {item["TP"]}
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  Pruh: {item["Pruh"]}
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  Kód vady: {item["Kod vady"]}
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  Název vady: {item["Nazev vady"]}
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  <a target="_blank" href={item["GPS link"]}>
                    Pozice na mapě
                  </a>
                </p>

                <p style={{ marginTop: "0", marginBottom: "0.5rem" }}>
                  <a
                    target="_blank"
                    href={`${process.env.PUBLIC_URL}/photos/${item.Fotoid}`}
                  >
                    <img
                      style={{
                        maxWidth: "100%",
                        display: "block",
                        height: "auto",
                      }}
                      src={`${process.env.PUBLIC_URL}/photos/${item.Fotoid}`}
                      alt={item.Fotoid}
                    />
                  </a>
                </p>
              </Popup>{" "}
            </Marker>
          ))}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default App;
